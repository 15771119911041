<template>
    <div>
        <div class="bg-gray-100 text-xs  h-40 flex justify-center items-center rounded-sm py-2 px-2 w-full">
            Form as seen by referee.

        </div>
    </div>
</template>

<script>
export default {
    name:"referee-form-view"
}
</script>

<style scoped>

</style>